@import "__config";
@import "sanitize.css/sanitize.css";

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap');

html {
	font-size: 16px;
}

body {
	margin: 0;
	padding: 0;
	font-family: $font_family;
	font-weight: 400;
	line-height: 1.8;
	letter-spacing: 0.025em;
	color: $font_color;
	background: $bg_color;

	@media #{$small_over} {
		line-height: 2.4;
	}
}

audio,
canvas,
iframe,
img,
svg,
video,
embed,
object {
	vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
p,
dl,
dt,
dd,
ul,
ol {
	margin: 0;
	padding: 0;
}

ul {
	list-style: none;
}

figure {
	margin: 0;
}

img {
	width: 100%;
	height: auto;
}

a {
	color: inherit;
	transition: all 0.3s ease;
	text-decoration: none;
}

address,
small,
input,
textarea,
select {
	font: inherit;
}

button {
	font: inherit;
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;
}

:focus {
	outline: none;
}

.pc {
	display: none;

	@media #{$small_over} {
		display: inline;
	}
}

.sp {
	@media #{$small_over} {
		display: none;
	}
}

.wrapper {
	position: relative;
	margin: 0 auto;

	@media #{$small_over} {
		min-width: #{$inner_width}px;
	}
}

/* スクロールアニメーション用
.anm {
	opacity: 0;
	transition: all 0.5s ease-out;
	&.show {
		opacity: 1;
		transform: translate(0, 0);
	}
	&--up {
		transform: translate(0, 100px);
	}
	&--lr {
		transform: translate(-60px, 0);
	}
	&--rl {
		transform: translate(60px, 0);
	}
}
*/

/* アコーディオン用 サンプル
.acdn-sample {
	&.acdn-active {
		height: 0;
		overflow: hidden;
		transition: all 0.5s ease;
		@media (--min) {
			height: auto;
		}
	}
	&.open {
		height: auto;
	}
}
*/

header {
	position: fixed;
	padding: 0 _calc(30);
	height: _calc(70);
	display: flex;
	align-items: center;
	width: 100%;
	z-index: 2;

	@media #{$small_over} {
		padding: 0 _calc(85);
		height: _calc(90);
	}

	h1 {
		width: _calc(120);
		line-height: 1;
		font-size: 10px;
		z-index: 3;

		@media #{$small_over} {
			width: _calc(150);
		}
	}

	.navi {
		position: fixed;
		top: _calc(25);
		right: _calc(30);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 12px;
		height: 12px;
		z-index: 3;

		@media #{$small_over} {
			display: none;
		}

		span {
			position: relative;
			display: block;
			background-color: #000;
			width: 12px;
			height: 1px;

			@media #{$small_over} {
				width: 13px;
			}

			&::before,
			&::after {
				content: "";
				display: block;
				width: 12px;
				height: 1px;
				background-color: #000;
				position: absolute;
				transition: all 0.3s ease;

				@media #{$small_over} {
					width: 13px;
				}
			}

			&::before {
				top: -4px;

				@media #{$small_over} {
					top: -5px;
				}
			}

			&::after {
				top: 4px;

				@media #{$small_over} {
					top: 5px;
				}
			}
		}

		&.on {
			span {
				background-color: transparent;

				&::before,
				&::after {
					width: 14px;
					top: 0;
					left: 50%;

					@media #{$small_over} {
						width: 20px;
					}
				}

				&::before {
					transform: translateX(-50%) rotate(45deg);
				}

				&::after {
					transform: translateX(-50%) rotate(-45deg);
				}
			}
		}
	}

	.menu {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		overflow: auto;
		background-color: #fff;
		padding: _calc(120) _calc(30) 0;
		transform: translate(0, 0);
		transition: all 0.3s ease-out;
		display: none;
		z-index: 2;

		&.show {
			display: block;
		}

		@media #{$small_over} {
			position: static;
			height: 100%;
			display: flex;
			align-items: center;
			padding: 0;
			overflow: visible;
			background-color: transparent;
		}

		&-list {
			@media #{$small_over} {
				margin: 0 0 0 auto;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			&__item {
				font-size: .9rem;
				line-height: 1;
				padding: 0 0 _calc(70);

				@media #{$small_over} {
					padding: 0 0 0 _calc(70);
				}

				a {
					display: block;
				}

				span {
					font-family: $font_en;
					font-size: 1rem;
				}
			}
		}
	}
}

.sec {
	&-title {
		font-family: $font_en;
		font-weight: 400;
		font-size: .9rem;
		line-height: 1;
		display: flex;
		align-items: center;

		&::after {
			content: "";
			display: block;
			width: 50px;
			height: 1px;
			background-color: #868686;
			margin: 0 0 0 10px;
		}
	}
}

.page {
	padding: _calc(80) 0 0 _calc(30);

	@media #{$small_over} {
		padding: _calc(120) _calc(160) 0 0;
		display: flex;
		align-items: center;
	}

	&-head {
		padding: _calc(40) _calc(30) _calc(60) 0;

		@media #{$small_over} {
			padding: 0 0 0 _calc(100);
			flex: 1;
		}

		&-title {
			font-family: $font_en;
			font-weight: 600;
			font-size: 1.8rem;
			line-height: 1;

			@media #{$small_over} {
				font-size: 52px;
			}
		}

		&-jp {
			font-size: .9rem;
			line-height: 1;
			font-weight: 500;
			padding: _calc(15) 0 0;

			@media #{$small_over} {
				font-size: 16px;
				padding: _calc(25) 0 0;
			}
		}

		&-text {
			font-size: .9rem;
			padding: _calc(30) 0 0;

			@media #{$small_over} {
				font-size: 14px;
				padding: _calc(40) 0 0;
			}
		}
	}

	&-image {
		@media #{$small_over} {
			width: _calc(750);
		}
	}

	&-subhead {
		&-title {
			font-family: $font_en;
			font-weight: 600;
			font-size: 1.8rem;
			line-height: 1;

			@media #{$small_over} {
				font-size: 42px;
			}
		}

		&-jp {
			font-size: .9rem;
			line-height: 1;
			font-weight: 500;
			padding: _calc(15) 0 0;

			@media #{$small_over} {
				font-size: 16px;
			}
		}

		&-text {
			font-size: .9rem;
			line-height: 1.8;
			padding: _calc(30) 0 0;

			@media #{$small_over} {
				font-size: 16px;
			}
		}
	}
}

.view {
	&-more {
		font-family: $font_en;
		font-weight: 400;
		font-size: 1rem;
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		&::after {
			content: "";
			display: block;
			width: 36px;
			height: 36px;
			border-radius: 50%;
			background-color: $key_color;
			background-image: url(../images/arrow_right.svg);
			background-repeat: no-repeat;
			background-size: 10px auto;
			background-position: center center;
			margin: 0 0 0 10px;

			@media #{$small_over} {
				width: 38px;
				height: 38px;
			}
		}
	}

	&-all {
		display: block;
		border: solid $key_color 2px;
		border-radius: 80px;
		width: 130px;
		line-height: 36px;
		text-align: center;
		font-family: $font_en;

		@media #{$small_over} {
			width: 140px;
			line-height: 40px;
		}
	}
}

.footer {
	position: relative;
	background-color: #262626;
	padding: _calc(40) _calc(30) _calc(90);
	z-index: 2;

	@media #{$small_over} {
		display: flex;
		padding: _calc(90) _calc(90) _calc(100);
	}

	&-head {
		@media #{$small_over} {
			padding: 0 _calc(80) 0 0;
		}

		.logo {
			width: _calc(150);
			font-size: 10px;
		}

		.copy {
			position: absolute;
			bottom: _calc(50);
			font-family: $font_en;
			font-weight: 300;
			font-size: .7rem;
			line-height: 1.4;
			color: #fff;

			@media #{$small_over} {
				position: static;
				padding: _calc(15) 0 0;
				font-size: 10px;
				line-height: 1.5;
			}
		}
	}

	&-service {
		padding: _calc(40) 0 0;

		@media #{$small_over} {
			border-left: solid #666 1px;
			padding: 0 _calc(70);
			flex: 1;
		}

		.title {
			font-size: .9rem;
			display: flex;
			align-items: center;
			color: #fff;
			gap: 15px;
			white-space: nowrap;

			&::after {
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				background-color: #666;
			}
		}

		ul {
			padding: _calc(20) 0 _calc(10);

			@media #{$small_over} {
				display: flex;
				padding: _calc(10) 0 _calc(30);
			}

			li {
				padding: 0 0 _calc(25);
				line-height: 1.5;

				@media #{$small_over} {
					padding: 0 _calc(80) 0 0;
				}

				a {
					color: #fff;
					font-size: .8rem;

					@media #{$small_over} {
						font-size: 13px;
					}
				}
			}
		}
	}

	&-other {
		border-top: solid #666 1px;
		padding: _calc(40) 0 _calc(100);

		@media #{$small_over} {
			border-top: none;
			border-left: solid #666 1px;
			margin: 0 0 0 auto;
			padding: _calc(5) _calc(100) 0 _calc(60);
		}

		li {
			padding: 0 0 _calc(25);
			line-height: 1.5;

			a {
				color: #fff;
				font-size: .8rem;

				@media #{$small_over} {
					font-size: 13px;
				}
			}
		}
	}

	&-line {
		position: absolute;
		right: _calc(20);
		bottom: _calc(90);
		opacity: 0;
		transition: opacity 0.5s;
		pointer-events: none;

		@media #{$small_over} {
			right: _calc(40);
			bottom: _calc(60);
		}

		&.show {
			opacity: 1;
			pointer-events: auto;
		}

		&.fix {
			position: fixed;
			bottom: _calc(30);

			@media #{$small_over} {
				bottom: _calc(60);
			}
		}

		a {
			font-size: .9rem;
			font-weight: 600;
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #fff;
			color: #000;
			border: solid #000 1px;
			padding: _calc(20) _calc(20);
			border-radius: 15px;

			@media #{$small_over} {
				width: 290px;
				padding: _calc(30) _calc(20);
			}

			&::after {
				content: "";
				display: block;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				border: solid #000 1px;
				background-image: url(../images/arrow_outward_black.svg);
				background-repeat: no-repeat;
				background-size: 14px auto;
				background-position: center center;
				margin: 0 0 0 10px;

				@media #{$small_over} {
					width: 32px;
					height: 32px;
				}
			}
		}
	}
}