$small_over: "only screen and ( min-width:481px )";

$key_color: #f6ab00;
$bg_color: #fff; // サイト全体の背景色
$font_color: #251E1C; // 基本のフォントカラー
$link_color: #00f; // リンクテキストのカラー
$orange: #ff914d;

$font_family: "Zen Kaku Gothic New", sans-serif;
$font_en: "Figtree", sans-serif;

$inner_width: 1200; // PCレイアウトのコンテンツの幅
$sp_ref: 750; // スマホレイアウトのデザインデータの幅

@function _calc($n) {
  @return calc(#{$n} / var(--lo1) * var(--lo2));
}

:root {
  --lo1: 390;
  --lo2: 100vw;
}

@media #{$small_over} {
  :root {
    --lo1: 1440;
    --lo2: 100vw;
  }
}